import "./App.css";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Capytech from "./pages/Capytech";
import { useEffect } from "react";


import { Routes, Route, Navigate, HashRouter  } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from "@mui/material";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-FYEFZFFXVX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const theme = createTheme({
  palette: {
    primary: {
      main: '#F9E5CF',
    },
    secondary: {
      main: '#0f364d',
    },
    text: {
      primary: '#f2f1e9',
      secondary: '#000000',
    },
    
  },
});

const App = () => {

  return (
    <ThemeProvider theme={theme}>
    <div style={{backgroundColor:"#ECDCCA"}}>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/capytech" element={<Capytech />} />
        <Route path="*" element={ <Navigate to="/" replace />} />
      </Routes>
    </HashRouter>
    </div>
   
    </ThemeProvider>
  );
}

export default App;
