import * as React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Link,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import Footer from "../Footer";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";


import ReactGA from "react-ga4";


const equipo = [
  {
    nombre: "Carlos Naranjo",
    puesto: "Scrum Master",
    img_url: "carlos.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/carlos-naranjo-rivas-976397253/",
  },
  {
    nombre: "Javiera Cardenas",
    puesto: "Diseño de nivel",
    img_url: "Javi.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/javiera-cardenas-jorquera/",
  },
  {
    nombre: "Javier Perez",
    puesto: "Product Owner",
    img_url: "Xav.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/javier-p-125979120/",
  },
  {
    nombre: "Daniel Pumarino",
    puesto: "Fullstack",
    img_url: "Dani.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/daniel-pumarino-burgos-2464931b0/",
  },
  {
    nombre: "Vania Gallardo",
    puesto: "Encargado de marketing",
    img_url: "Vania.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/vania-gallardo/",
  },
  {
    nombre: "Guillermo Fuentes",
    puesto: "Fullstack",
    img_url: "Memo.jpeg",
    lnkdn_url: "https://www.linkedin.com/in/guillermo-fuentes-b48125253/",
  },
];

export default function Company() {
  const [user, setUser] = useState(null);
  const [loged, setLoged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const link = window.location.hash.split("#")[1]
    const title = "Sobre Capytech"
    ReactGA.send({ hitType: "pageview", page: link, title });
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundColor: "#FAECDD",
          padding: "2%",
        }}
      >
        <Container maxWidth={"xl"}>
          <Typography
            variant="h2"
            align="justify"
            textAlign={"center"}
            marginBottom={5}
          >
            <img
              src={"/logo tech.png"}
              alt="Carpinch.io"
              width="300"
              height="200"
              align="center"
            />
            <br />
            Capytech
          </Typography>
          <Typography variant="h5" align="justify">
            <Box component="span" fontWeight="fontWeightBold">
              Somos Capytech
            </Box>
            , una pre-empresa de desarrollo de videojuegos que busca ayudar a los
            jovenes a desarrollar sus conocimientos en el area de ciberseguridad
            por medio de la gamificacion.
          </Typography>
        </Container>
      </div>
      <Container maxWidth={"xl"} style={{ padding: "2%" }}>
        <Grid container justifyContent="center" alignItems="center" spacing={0}>
          <Grid item md={12}>
            <Typography
              variant="h2"
              align="justify"
              marginBottom={5}
              textAlign={"center"}
            >
              <img src="/capymision2.png" alt="mision" width="180" />
              <br />
              Misión
            </Typography>
            <Typography variant="h5" align="justify">
              <Box component="span" fontWeight="fontWeightBold">
              Nuestra misión es preparar a la próxima generación desde temprana
              edad con las habilidades básicas de seguridad en línea, utilizando
              la gamificación como herramienta central.
              </Box>{" "}
              Brindándoles las
              herramientas y conocimientos necesarios para navegar de manera
              segura y confiada en un mundo digital en constante evolución.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: "#FAECDD",
          padding: "2%",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item md={12}>
              <Typography
                variant="h2"
                align="justify"
                textAlign={"center"}
                marginBottom={5}
              >
                <img src="/capyvisio2.png" alt="vision" width="180" />
                <br />
                Visión
              </Typography>
              <Typography variant="h5" align="justify">
                <Box component="span" fontWeight="fontWeightBold">
                Nuestra visión es convertirnos en líderes en soluciones educativas de ciberseguridad basadas en la gamificación, manteniendo su relevancia y eficacia en un mundo digital globalizado.
                </Box>{" "}
                De esta manera, contribuiremos a crear un entorno digital más seguro y protegido para las generaciones futuras.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container maxWidth={"xl"} sx={{ padding: "2%" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item md={12} alignContent={"center"}>
                <Grid item md={12}>
                  <Typography
                    variant="h2"
                    align="justify"
                    marginBottom={5}
                    textAlign={"center"}
                  >
                    Nuestro equipo
                  </Typography>
                </Grid>
                <Grid container spacing={4} justifyContent="center">
                  {equipo.map((miembro, index) => (
                    <Grid item key={index}>
                      <Card
                        sx={{ maxWidth: 300, backgroundColor: "#ecdcca" }}
                        align="center"
                      >
                        <CardActionArea>
                          <a href={miembro.lnkdn_url}>
                            <CardMedia
                              component="img"
                              height="200"
                              image={miembro.img_url}
                              alt="green iguana"
                            />
                          </a>
                          <CardContent>
                            <Typography
                              variant="h5"
                              color="black"
                              component="div"
                            >
                              {miembro.nombre}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                              {miembro.puesto}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
