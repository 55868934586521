import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "secondary.main",
        p: 3,
        flex: "end",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div>
              <img src="/gorila.png" alt="redes" width="130" />
            </div>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Menu
            </Typography>
            <Typography
              variant="a"
              color="text.primary"
              sx={{ textDecoration: "underline" }}
              onClick={(e) => navigate("/")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              Inicio
            </Typography>
            <br />
            <Typography
              variant="a"
              color="text.primary"
              onClick={(e) => navigate("/sobre")}
              sx={{ textDecoration: "underline" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              ¿Qué es carpinchio?
            </Typography>
            <br />
            <Typography
              variant="a"
              color="text.primary"
              onClick={(e) => navigate("/capytech")}
              sx={{ textDecoration: "underline" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              ¿Quienes somos?
            </Typography>
            <br />
            <Typography
              variant="a"
              color="text.primary"
              onClick={(e) => navigate("/contacto")}
              sx={{ textDecoration: "underline" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              Contacto
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "-25%",
              }}
              onClick={(e) => navigate("/capytech")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              <img src="/logo tech.png" alt="redes" width="130" />
            </div>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              onClick={(e) => navigate("/capytech")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              <Link color="text.primary">Sobre capytech</Link>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Somos Capytech, una pre-empresa de desarrollo de videojuegos que busca
              ayudar a los jovenes a desarrollar sus conocimientos en el area de
              ciberseguridad por medio de la gamificacion.
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "-25%",
              }}
            >
              <img src="/redes4.png" alt="redes" width="200" />
            </div>
            <Typography variant="h6" color="text.primary" gutterBottom>
              <Typography>
                <Box component="span" fontWeight="fontWeightMedium">
                  Conoce nuestras redes sociales
                </Box>
              </Typography>
            </Typography>
            <Stack direction={"row"} alignItems="center">
              <Link
                href="https://www.facebook.com/profile.php?id=61550065261276&mibextid=ZbWKwL"
                color="text.primary"
              >
                <Facebook />
              </Link>
              <Link
                href="https://www.instagram.com/carpinch.io/"
                color="text.primary"
                sx={{ pl: 1, pr: 1 }}
              >
                <Instagram />
              </Link>
            </Stack>
            <div />
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "-25%",
              }}
              onClick={(e) => window.open("https://www.feriadesoftware.cl/")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onMouseOut={(e) => {
                e.target.style.cursor = "default";
              }}
            >
              <img src="/fesw-logo.png" alt="logo_fesw" width="230" />
            </div>
            <Typography variant="h6" color="text.primary" gutterBottom>
              <Link href="https://www.feriadesoftware.cl/" color="text.primary">
                XXXI Feria de Software, 2023
              </Link>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Departamento de informática
            </Typography>
            <Typography variant="body2" color="text.primary">
              Universidad Técnica Federico Santa María
            </Typography>
            <Typography variant="body2" color="text.primary">
              San Joaquín, Santiago, Chile
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
