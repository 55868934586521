import * as React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Link,
  Button,
  Stack,
  Card,
  Paper,
  Divider,
} from "@mui/material";
import Footer from "../Footer";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactGA from "react-ga4";

export default function About() {
  useEffect(() => {
    const link = window.location.hash.split("#")[1]
    const title = "Sobre Carpinch.io"
    ReactGA.send({ hitType: "pageview", page: link, title });
  }, []);
  const [user, setUser] = useState(null);
  const [loged, setLoged] = useState(false);
  const navigate = useNavigate();

  let isSmallScreen = useMediaQuery("(max-width:640px)");

  const tarjetas = [
    {
      nombre: "Desafios de phishing por correo",
      img_url: "correo.png",
      descripcion:
        "En este minijuego se enseña a identificar correos maliciosos",
    },
    {
      nombre: "Desafios de phishing por buscadores",
      img_url: "buscador.png",
      descripcion:
        "En este minijuego se enseña a identificar resultados de búsqueda maliciosos",
    },
    {
      nombre: "Desafios de phishing por sms",
      img_url: "sms2.png",
      descripcion:
        "En este minijuego se enseña a identificar mensajes maliciosos"
    },
    {
      nombre: "Desafios de estafas por publicidad",
      img_url: "pub.png",
      descripcion:
        "En este minijuego se enseña a identificar publicidades maliciosas en redes sociales",
    },{
      nombre: "Desafios de contraseñas",
      img_url: "contra.png",
      descripcion:
        "En este minijuego se enseña a crear contraseñas seguras y a identificar contraseñas inseguras",
    },{
      nombre: "Desafios de busquedas pasivas",
      img_url: "prox.png",
      descripcion:
        "En este minijuego se enseña a identificar información sensible en redes sociales"
    },{
      nombre: "Plaza de la ciberseguridad",
      img_url: "plaza.png",
      descripcion:
        "En este espacio se enseña por medio de interacciones con personajes de la plaza, conceptos de ciberseguridad",
    },{
      nombre: "Crea tus propios desafios",
      img_url: "prox.png",
      descripcion:
        "¿Eres un experto en ciberseguridad? ¡Crea tus propios desafios y compártelos con tus estudiantes!",
    }
  ];

  return (
    <>
      <ResponsiveAppBar />

      <div
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
              >
                <Grid item md={1} />
                <Grid item md={5}>
                  <img
                    src="./juego.png"
                    alt="Carpinch.io"
                    width={isSmallScreen ? 360 : 480}
                  />
                </Grid>
                <Grid
                  item
                  md={5}
                  alignContent={"center"}
                  marginTop={"2%"}
                  marginBottom={"5%"}
                >
                  <Grid item md={12}>
                    <Typography
                      variant="h2"
                      align="justify"
                      marginBottom={5}
                      textAlign={"center"}
                      sx={{ fontWeight: "bold" }}
                    >
                       ¿En que consiste Carpinch.io?
                    </Typography>
                    <Typography variant="body1" align="justify">
                      <Box component="span" fontWeight="fontWeightBold">
                      Carpinch.io es un videojuego con elementos multijugador que
                  tiene el objetivo de enseñar ciberseguridad, a través de
                  minijuegos, donde cada partida es una competencia entre dos
                  equipos.
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#FAECDD",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
              >
                <Grid
                  item
                  md={5}
                  alignContent={"center"}
                  marginTop={"2%"}
                  marginBottom={"5%"}
                >
                  <Grid item md={12}>
                    <Typography
                      variant="h2"
                      align="justify"
                      marginBottom={5}
                      textAlign={"center"}
                      sx={{ fontWeight: "bold" }}
                    >
                      Problematica actual
                    </Typography>
                    <Typography variant="body1" align="justify">
                      <Box component="span" fontWeight="fontWeightBold">
                        Los crímenes virtuales son cada vez más frecuentes y a
                        pesar de eso{" "}
                      </Box>
                      <Box component="span" fontWeight="fontWeightBold">
                        las instituciones de educación media no acostumbran a
                        enseñar conceptos y técnicas de ciberseguridad a sus
                        estudiantes
                      </Box>
                      , evitando así que se propague la cultura de
                      ciberseguridad y convirtiéndolos en presas fáciles para
                      los ciberdelincuentes.{" "}
                      <Box component="span" fontWeight="fontWeightBold">
                        Estos pueden ser víctimas de múltiples ciberdelitos, que
                        van desde vulneración a cuentas hasta ser víctimas de
                        ransomware.
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}>
                  <img
                    src="./ciber.png"
                    alt="Carpinch.io"
                    width={isSmallScreen ? 360 : 480}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
              <Typography
                variant="h2"
                align="center"
                marginTop={"2%"}
                marginLeft={"2%"}
                sx={{ fontWeight: "bold" }}
              >
                ¿Como lo solucionamos?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Divider
                sx={{ mx: "auto", width: isSmallScreen ? "20em" : "40em" }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="body1"
                align="center"
                marginTop={"2%"}
                sx={{ fontWeight: "bold" }}
              >
                Conoce algunos de los minijuegos de carpinch.io
              </Typography>
            </Grid>
            {tarjetas.map((tarjeta) => (
              <Grid item md={3} marginTop={"2%"}>
                <Card
                  sx={{
                    maxWidth: 345,
                    minHeight: 460,
                    backgroundColor: "#FAECDD",
                    borderRadius: "2%",
                  }}
                >
                  <img
                    src={tarjeta.img_url}
                    alt="Carpinch.io"
                    width={345}
                  />
                  <Typography
                    variant="h5"
                    align="center"
                    marginTop={"2%"}
                    sx={{ fontWeight: "bold" }}
                    color={"text.secondary"}
                    p={2}
                  >
                    {tarjeta.nombre}
                  </Typography>
                  <Typography variant="body1" align="center" color={"text.secondary"} p={2}>
                    {tarjeta.descripcion}
                  </Typography>
                </Card>
                </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      <Grid container justifyContent="center" alignItems="center" pt={2}>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
