import * as React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Link,
  Button,
  Stack,
  Card,
  Paper,
  Divider,
} from "@mui/material";
import Footer from "../Footer";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactGA from "react-ga4";

export default function Home() {
  const [user, setUser] = useState(null);
  const [loged, setLoged] = useState(false);
  const navigate = useNavigate();

  let isSmallScreen = useMediaQuery("(max-width:640px)");

  useEffect(() => {
    const link = window.location.hash.split("#")[1]
    const title = "Contacto"
    ReactGA.send({ hitType: "pageview", page: link, title });
  }, []);

  return (
    <>
      <ResponsiveAppBar />

      <Container maxWidth={"xl"} >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={5}
            >
              <Grid
                item
                md={12}
                xs={12}
                lg={5}
                alignContent={"center"}
                marginTop={"2%"}
                marginBottom={"5%"}
              >
                <Grid item md={12}>
                  <Typography
                    variant="h2"
                    align="justify"
                    marginBottom={5}
                    textAlign={"center"}
                  >
                    Contacto
                  </Typography>
                  <Typography variant="body1" align="justify">
                    <Box component="span" fontWeight="fontWeightBold">
                      Para más información puedes contactarnos mediante nuestras
                      redes sociales o si lo prefieres enviarnos un correo
                      directamente.
                    </Box>
                    <br />
                    <br />
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  alignContent={"center"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  marginTop={4}
                >
                  <Typography
                    variant="h5"
                    align="justify"
                    marginBottom={5}
                    textAlign={"center"}
                  >
                    Email: capytech.io@gmail.com
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  alignContent={"center"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  marginTop={4}
                >
                  <Typography variant="text" align="center">
                    <Box component="span" fontWeight="fontWeightMedium">
                      Redes sociales
                    </Box>
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={12}
                  alignContent={"center"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  marginTop={1}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Stack direction="row" alignItems="center" spacing={4}>
                    <Button href="https://www.instagram.com/carpinch.io/">
                      <InstagramIcon
                        fontSize="large"
                        sx={{ color: "#E1306C" }}
                      />
                    </Button>
                    <Button href="https://www.facebook.com/profile.php?id=61550065261276&mibextid=ZbWKwL">
                      <FacebookIcon
                        fontSize="large"
                        sx={{ color: "#3B5998" }}
                      />
                    </Button>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item md={1} />

              <Grid item md={12} xs={12} lg={5}>
                <iframe
                  width="100%"
                  height="350"
                  src="https://www.openstreetmap.org/export/embed.html?bbox=-70.62599658966066%2C-33.49504302417617%2C-70.61183452606203%2C-33.486990226832056&amp;layer=mapnik&amp;marker=-33.49101671911274%2C-70.61891555786133"
                ></iframe>
                <br />
                <small>
                  <a href="https://www.openstreetmap.org/?mlat=-33.49102&amp;mlon=-70.61892#map=17/-33.49102/-70.61892">
                    Ver el mapa más grande
                  </a>
                </small>
                <br />
                <small>
                  {" "}
                  Encuentranos en la 31va edición de la feria de software - Av.
                  Vicuña Mackenna 3939, 8940897 San Joaquín, Región
                  Metropolitana
                </small>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Grid container justifyContent="center" alignItems="center" marginTop={"3em"}>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
