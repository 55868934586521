import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";

const ResponsiveAppBar = (props) => {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const pages = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "¿Qué es carpinchio?",
      url: "/sobre",
    },
    {
      name: "¿Quienes somos?",
      url: "/capytech",
    },
    {
      name: "Contacto",
      url: "/contacto",
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none"}, background:"#0f364d" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", "& .MuiMenu-paper": 
                { backgroundColor: "#0f364d", },  },
              }}
              
              
              
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => navigate(page.url)}
                sx={{backgroundColor:"#0f364d"}}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <div
            onFocus={() => {
              // Tu código aquí para el evento onFocus
            }}
            onMouseOver={() => {
              // Cambiar el cursor a "pointer" al pasar el mouse sobre el div
              document.getElementById("myDiv").style.cursor = "pointer";
            }}
            onMouseOut={() => {
              // Cambiar el cursor de vuelta al estado predeterminado al salir del div
              document.getElementById("myDiv").style.cursor = "auto";
            }}
            id="myDiv"
          >
            <Avatar
              src="/capi.png"
              sx={{
                mr: 1,
                width: 60,
                height: 60,
                marginTop: 1,
                marginBottom: 1,
              }}
              onClick={(e) => navigate("/")}
            />
          </div>
          <Typography
            variant="h6"
            noWrap
            color={"text.primary"}
            onClick={(e) => navigate("/")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onMouseOut={(e) => {
              e.target.style.cursor = "default";
            }}
          >
            Carpinch.io
          </Typography>

          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => navigate(page.url)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
